import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { Link, useIntl } from "gatsby-plugin-intl";
import qs from "query-string";
import { connect } from "react-redux";
import { appRedirect } from "../redux/app/actions";
import {
	authRecoveryLoginInit,
	authPasswordChangeInit,
} from "../redux/auth/actions";
import l from "lodash";
import PageHeader from "../components/Common/PageHeader";
import { colors } from "../assets/styles/vars";
import { Breadcrumb, CustomButton } from "../assets/styles/globalCommon";
import {
	PageContent,
	PageContentWrapper,
	PageTwoColWrapper,
} from "../assets/styles/pageCommon";
import { SliderButton } from "@typeform/embed-react";
import { InputBlock } from "../assets/styles/formCommon";
import { ReactSVG } from "react-svg";

const PassRecoveryPage = ({
	location,
	authResolved,
	loggedIn,
	passwordChangeErrors,
	appRedirect,
	authRecoveryLoginInit,
	authPasswordChangeInit,
}) => {
	const intl = useIntl();

	const [showPassword, setShowPassword] = useState(false);
	const [password1, setPassword1] = useState("");
	const [password2, setPassword2] = useState("");

	useEffect(() => {
		if (authResolved) {
			if (!loggedIn) {
				// no esta logueado, intentar loguearlo por recovery key
				const values = qs.parse(location.search);
				if (!l.isEmpty(values.key)) {
					authRecoveryLoginInit(intl.locale, values.key);
				} else {
					// no esta logueado y no hay recovery key, sacarlo de aca
					appRedirect({ to: "/" });
				}
			} else {
				// esta logueado, nos quedamos aca
			}
		}
	}, [authResolved, loggedIn]);

	const handlePassword1Change = (e) => {
		setPassword1(e.target.value);
	};

	const handlePassword2Change = (e) => {
		setPassword2(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		authPasswordChangeInit(intl.locale, {
			token: localStorage.getItem("token"),
			password1,
			password2,
		});
	};

	return (
		<Layout>
			<Seo title="Crear contraseña" />
			<section>
				<PageHeader title="Crear contraseña" />

				<PageContentWrapper bgColor={colors.grayF2}>
					<PageContent width="1050px">
						<PageTwoColWrapper>
							<div className="pageCol leftCol">
								<Breadcrumb>
									<Link to="/account">Mi cuenta</Link>
									<a>&gt;</a>
									<Link to="/information">Mis datos</Link>
									<a>&gt;</a>
									<a>Nueva contraseña</a>
								</Breadcrumb>
								<h4>Nueva contraseña</h4>
								<p>
									Ingresa tu nueva contraseña, no se lo ponga fácil a
									los hackers, pon una clave con números, letras y
									algún símbolo 😉.
								</p>
								<br />
								<br />

								{authResolved && !loggedIn && (
									<>
										<p>
											<strong>Link inválido o expirado</strong>
										</p>
										<br />
										<br />
									</>
								)}

								{authResolved && loggedIn && (
									<form onSubmit={handleSubmit}>
										{/* PASSWORD */}
										<InputBlock
											noLabel={true}
											className={`${
												l.includes(
													passwordChangeErrors,
													"missing-password1",
												) ||
												l.includes(
													passwordChangeErrors,
													"mismatched-passwords",
												)
													? "hasError"
													: ""
											}`}
										>
											<div className="inputWrapper">
												<div className="inputOut">
													<input
														type={
															showPassword ? "text" : "password"
														}
														placeholder="Introduce tu contraseña o crea una nueva"
														value={password1}
														onChange={handlePassword1Change}
													/>
													<div className="inputAction">
														<CustomButton
															className="ghost withUnderline"
															onClick={(e) => {
																e.preventDefault();
																setShowPassword(!showPassword);
															}}
														>
															<p>
																{showPassword
																	? "Ocultar"
																	: "Mostrar"}
															</p>
														</CustomButton>
													</div>
												</div>
											</div>
										</InputBlock>

										{/* PASSWORD 2 */}
										<InputBlock
											noLabel={true}
											className={`${
												l.includes(
													passwordChangeErrors,
													"missing-password2",
												) ||
												l.includes(
													passwordChangeErrors,
													"mismatched-passwords",
												)
													? "hasError"
													: ""
											}`}
										>
											<div className="inputWrapper">
												<div className="inputOut">
													<input
														type={
															showPassword ? "text" : "password"
														}
														placeholder="Repite tu contraseña"
														value={password2}
														onChange={handlePassword2Change}
													/>
													<div className="inputAction">
														<CustomButton
															className="ghost withUnderline"
															onClick={(e) => {
																e.preventDefault();
																setShowPassword(!showPassword);
															}}
														>
															<p>
																{showPassword
																	? "Ocultar"
																	: "Mostrar"}
															</p>
														</CustomButton>
													</div>
												</div>
											</div>
											{l.includes(
												passwordChangeErrors,
												"mismatched-passwords",
											) && (
												<div className="inputMsg">
													<ReactSVG
														src="/img/icons/icon-close.svg"
														wrapper="span"
														className="icon withPadding"
													/>
													<p>Las contraseñas no coinciden</p>
												</div>
											)}
										</InputBlock>
										<br />

										<CustomButton
											type="submit"
											className="withIcon iconBig"
										>
											<p>Continuar</p>
											<ReactSVG
												src="/img/icons/icon-email.svg"
												wrapper="span"
												className="icon"
											/>
										</CustomButton>
									</form>
								)}

								<p>
									Si tienes más duda, recuerda visitar nuestra sección
									de{" "}
									<Link to="/help" target="_blank">
										preguntas frecuentes
									</Link>{" "}
									o envíanos un consulta.
								</p>
								<br />
								<SliderButton id="kr8xczVw">
									<CustomButton className="ghost withUnderline">
										<p>Enviar Consulta</p>
									</CustomButton>
								</SliderButton>
							</div>
						</PageTwoColWrapper>
					</PageContent>
				</PageContentWrapper>
			</section>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const { passwordChangeErrors } = state.auth;
	return {
		authResolved: state.auth.resolved,
		loggedIn: state.auth.loggedIn,
		passwordChangeErrors,
	};
};

const mapDispatchToProps = {
	appRedirect,
	authRecoveryLoginInit,
	authPasswordChangeInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(PassRecoveryPage);
